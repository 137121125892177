const findLastDuplicateIndex = (list) => {
    const lastIndexMap = {};
    let lastDuplicateIndex = -1;
  
    for (let i = 0; i < list.length; i++) {
      const item = list[i];
      if (lastIndexMap[item] === undefined) {
        lastIndexMap[item] = i;
      } else {
        lastDuplicateIndex = i;
        break;
      }
    }
  
    return lastDuplicateIndex;
}

export default findLastDuplicateIndex;