import React, { useState , useEffect, useContext, useRef} from "react";
import { useNavigate } from 'react-router-dom';
import Cookies from "universal-cookie";
import { auth, googleProvider } from "../config/firebase-config";
import {
  createUserWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from "firebase/auth";
// import GoogleButton from 'react-google-button';

/*  ========= COMPONENTS ========= */
import GoogleButton from './GoogleButton'

/*  ========= BACKEND API ========= */
import mainApi from "../apis/mainApi";

/*  ========= CONTEXT ========= */
import { Context as AuthContext } from '../context/AuthContext';

/*  ========= STYLESHEETS ========= */
// import styles from "../styles/Login.css";
import "../styles/Login.css";

/*  ========= COOKIE ========= */
const cookies = new Cookies();

const App = () => {
  /*  ========= STATE ========= */
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isNotValidEmail, setIsNotValidEmail] = useState(true);
  const [isNotValidPassword, setIsNotValidPassword] = useState(true);

  /*  ========= REFERENCE ========= */
  const emailRef = useRef();
  const passwordRef = useRef();
  const getEmailRefValue = () => emailRef.current;
  const getPasswordRefValue = () => passwordRef.current;
  // const [isLoading, toggleLoading] = useBooleanToggle(false);

  /*  ========= CONTEXT ========= */
  const { state: authState, loadToken, signup } = useContext(AuthContext);
  const authStorage = JSON.parse(localStorage.getItem("authContext"));
  const jwtoken= authState.token

  const navigate = useNavigate();

  /*  ========= GET COOKIE ========= */
  const initToken = cookies.get("user");

  useEffect(() => {
    // console.log("useEffect");
    // console.log('cookie:', initToken);
    // console.log('authState.token:', authState.token);
    if (jwtoken || initToken) {
      // Redirect to /login route
      // console.log('check jwt');
      navigate("/");
    }
    return
  }, [])

  const signUp = () => {
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(getEmailRefValue())) {
        // toggleLoading(true);
        console.log(1);
        signup(getEmailRefValue(), getPasswordRefValue())
        .then((res) => {
            console.log(2)
            // console.log("res: ", res)
            if (!res) {
              console.log(3)
              setError("We couldn't sign you in! Please try again later.");
            }
            navigate("/");
            // toggleLoading(false);
        })
        .catch((error) => {
          console.log(5)
          // console.error(error);
          // toggleLoading(false);
        });
    } else {
      console.log(4)
        setError("Please input a valid email address!");
        // toggleLoading(false);
    }
 };

  const signInWithGoogle = async () => {
    try {
      const googleSignInResult = await signInWithPopup(auth, googleProvider);
      mainApi
      .post("/sign-up", 
        {
          email: googleSignInResult.user.email, 
          uid: googleSignInResult.user.uid,
          type: 'google',
        }
      )
      .then((response) => {
        cookies.set('user',response.data.token, {path: '/'})
        cookies.set('user_id',response.data.userId, {path: '/'})
        navigate("/");
        // console.log(response);
        // setUrl(response.data.endpoint)
      })
      .catch((error) => {
        console.log(error);
        // reject(error)
      })
    } catch (err) {
      console.error(err);
    }
  };


  const handleRedirect = (event) => {
    event.preventDefault()
    navigate("/login")
  };

  return (
    <div
      className="signInContent"
    >
      <div
        className="signInInsiderContainer"
      >
        <div
          className="signInInsiderContainerTop"
        >
          <h1 
            // className={{error: styles.header}}
          >
            Sign Up
          </h1>
        </div>
        <div
          className="signInInsiderContainerMid"
        >
          <div
            style={{
              "display": "flex",
              "justifyContent": "center"
            }}
          >
            <GoogleButton
              onClickFunction={signInWithGoogle}
            />
          </div>
        </div>
        <div
          className="signInInsiderContainerBottom"
        >
          <button 
                onClick={handleRedirect}
                className="standardButton"
              >
                Back to Login
            </button>
        </div>
        {/* <form 
          // onSubmit={handleSubmit}
          onSubmit={(e) => {
            e.preventDefault();
            signUp();
        }}
        >
          <input
            type="email"
            placeholder="Email"
            onChange={(e) => {
              // console.log(e.target.value);
              setEmail(e.target.value);
              emailRef.current = e.target.value;
            }}
          />
          <input
            type="password"
            placeholder="Password"
            onChange={(e) => {
              // console.log(e.target.value);
              setPassword(e.target.value);
              passwordRef.current = e.target.value;
            }}
            // secureTextEntry={true}
          />
          <button 
            type="submit"
          >
            Sign Up
          </button>
        </form> */}
        {/* <div
          style={{
            "display": "flex",
            "justifyContent": "center"
          }}
        >
          <GoogleButton
            onClickFunction={signInWithGoogle}
          />
        </div> */}
        {/* <div>
          <button 
              onClick={handleRedirect}
              className="standardButton"
            >
              Back to Login
          </button>
        </div> */}
        {/* {authState.errorMessage
                  ?<h3 
                      style={{marginTop: '20px', marginBottom: '-20px', color: 'red'}}
                  >
                      {authState.errorMessage}
                  </h3>
                  : null
              } */}
      </div>
    </div>
  );
};

export default App;
