const getLastMatchingElement = (arr, input2, unnestLevel) => {
    for (let i = arr.length - 1; i >= 0; i--) {
        const element = arr[i];
        if (element[`${input2}`].level === unnestLevel) {
            return element;
        }
    }
    return null;
}

export default getLastMatchingElement;