// function flattenKeys(jsonObj, parentKey = '', flattenedKeys = []) {
//   for (let key in jsonObj) {
//     const currentKey = `${parentKey}${key}`;
//     if (typeof jsonObj[key] === 'object' && !Array.isArray(jsonObj[key])) {
//       flattenedKeys.push(currentKey); // Include the current key
//       flattenKeys(jsonObj[key], `${currentKey}.`, flattenedKeys);
//     } else {
//       flattenedKeys.push(currentKey);
//     }
//   }
//   return flattenedKeys;
// }

const flattenJsonKeys = (json, prefix = '') => {
  let keys = [];

  for (let key in json) {
    if (typeof json[key] === 'object' && !Array.isArray(json[key])) {
      const nestedKeys = flattenJsonKeys(json[key], `${prefix}${key}.`);
      keys = keys.concat(nestedKeys);
    } else {
      keys.push(`${prefix}${key}`);
    }
  }
  if (prefix === '') {
    keys.push(prefix);
  }
  return keys;
}


export default flattenJsonKeys;