import React, { useState, useEffect } from 'react';
import { FcGoogle } from "react-icons/fc";

/*  ========= STYLESHEETS ========= */
import "../styles/googleButton.css";

const GoogleButton = ({onClickFunction}) => {
    return(
        <div
            style={{
                display:"flex",
                justifyContent:"center"
            }}
        >
            <button
                onClick={onClickFunction}
                className='googleButton'
            >
                <FcGoogle 
                    style={{
                        minHeight:"40px",
                        minWidth:"30px",

                    }}
                />
                Sign In with Google
            </button>
        </div>
    )
}

export default GoogleButton;