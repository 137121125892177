import React from 'react';

import "../styles/Subscription.css"

const SubscriptionComponent = () => {
  return (
    <div className='SubscriptionContent'>
      <h2>Subscription</h2>
      <p>
        Under Construction. We will inform you when this is active.
        <br />
        Right now, we are in beta, so it's free.
      </p>
    </div>
  );
};

export default SubscriptionComponent;
