// Update the child and parent keys for those rows that are linked
// to a changing input value
const updateValuesChildParentKeys = (data, value, newValue) => {
  // console.log(data);
    data.forEach(obj => {
      const input2 = obj.input2;
      // console.log("input2");
      // console.log(input2);
      // console.log(`value: ${value}`);
      // console.log(`input2.parentKey: ${input2.parentKey}`);
      // console.log(input2.parentKey === value);
      if (input2.parentKey != '' && input2.childkey != '' && value != '') {
        if (input2.parentKey === value) {
          // console.log("enter first if");
          input2.parentKey = newValue;
        }
        if (input2.childkey === value) {
          // console.log("enter second if");
          input2.childkey = newValue;
        }
      }
    });
    return data;
}

export default updateValuesChildParentKeys;