import React from 'react';
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { UilEstate, 
  UilSignout, 
  UilCreditCard, 
  UilCloudComputing, 
  UilCloudDataConnection,
  UilClipboardBlank } 
from '@iconscout/react-unicons'
import "../styles/Navbar.css"
import Cookies from "universal-cookie";
import { signOut } from "firebase/auth";
import { auth } from "../config/firebase-config";

/*  ========= COOKIE ========= */
const cookies = new Cookies();


const Navbar = () => {
  const currentPath = window.location.pathname;

  // console.log("currentPath: ", currentPath);
  // console.log("currentPath === /: ", currentPath === "/");

  const navigate = useNavigate();

  const handleSignOut = () => {
    signOut(auth).then(() => {
      // Logout successful
      // console.log('User logged out successfully.');
      cookies.remove("user", { path: "/" });
      cookies.remove("user_id", { path: "/" });
      cookies.remove("jwt", { path: "/" });
      navigate("/login");
    }).catch((error) => {
      // An error occurred during logout
      console.error('Error logging out:', error);
    });
  };

  return (
    // <div className="navbar" style={{ display: 'flex', flexDirection: 'column', width: '15vw', height: '100vh', maxHeight: '100vh', 'justifyContent': 'space-between'}}>
    <div className="navbar">
        <div className="navbarContent">
            {/* Your navigation links */}
            <div className="topLinks">
              <Link 
                  className="link"
                  to="/" 
                  style={currentPath === "/" ? {"backgroundColor":"#bf326d"}: null}
              >
                <UilEstate 
                  style={{alignSelf: "center", margin: '10px'}}
                />
                Home
              </Link>
              <Link 
                  className="link"
                  to="/sources" 
                  style={currentPath === "/sources" ? {"backgroundColor":"#bf326d"}: null}
              >
                <UilCloudComputing 
                  style={{alignSelf: "center", margin: '10px'}}
                />
                Sources
              </Link>
              <Link 
                  className="link"
                  to="/destinations" 
                  style={currentPath === "/destinations" ? {"backgroundColor":"#bf326d"}: null}
              >
                <UilCloudDataConnection 
                  style={{alignSelf: "center", margin: '10px'}}
                />
                Destinations
              </Link>
            </div>
            <div className="bottomLinks">
              <Link 
                className="link"
                to="https://forms.gle/uMSYtYbM8w1Fg1Bo6" 
                target="_blank"
                style={currentPath === "/feedback" ? {"backgroundColor":"#bf326d"}: null}
              >
                <UilClipboardBlank 
                  style={{alignSelf: "center", margin: '10px'}}
                />
                Feedback
              </Link>
              <Link 
                className="link"
                to="/subscription" 
                style={currentPath === "/subscription" ? {"backgroundColor":"#bf326d"}: null}
              >
                <UilCreditCard 
                  style={{alignSelf: "center", margin: '10px'}}
                />
                Subscribe
              </Link>
              <Link 
                className="link"
                style={currentPath === "/logout" ? {"backgroundColor":"#bf326d"}: null}
                onClick={handleSignOut}
              >
                <UilSignout 
                  style={{alignSelf: "center", margin: '10px'}}
                />
                Log Out 
              </Link>
            </div>
        </div>
    </div>
  );
};

export default Navbar;

