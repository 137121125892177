import React, { useState } from 'react';

const CircularComponent = ({ isEnabled }) => {
//   const [isOn, setIsOn] = useState(false);

//   const handleClick = () => {
//     if (isEnabled) {
//       setIsOn((prevIsOn) => !prevIsOn);
//     }
//   };

//   const color = isEnabled ? (isOn ? 'green' : 'red') : 'gray';
  const color = isEnabled ? '#10ad10' : '#c50404';

  return (
    <div
      style={{
        width: '20px',
        height: '20px',
        borderRadius: '50%',
        backgroundColor: color,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: isEnabled ? 'pointer' : 'not-allowed',
      }}
    //   onClick={handleClick}
    >
      {/* {isEnabled ? (isOn ? 'ON' : 'OFF') : 'DISABLED'} */}
    </div>
  );
};

export default CircularComponent;
