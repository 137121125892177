import React, { useState, useEffect, useContext } from "react";
import { useNavigate, Link } from 'react-router-dom';
import Cookies from "universal-cookie";
import Switch from '@mui/material/Switch';
import Skeleton from '@mui/material/Skeleton';
import { UilTrash, UilArrowRight } from '@iconscout/react-unicons'
import { auth } from "../config/firebase-config";
import Button from '@mui/material/Button';
// import { useFetch } from "react-fetch";

/*  ========= COMPONENTS ========= */
import ToggleSwitch from "./ToggleSwitch";
import Navbar from './Navbar';

/*  ========= BACKEND API ========= */
import mainApi from "../apis/mainApi";

/*  ========= STYLESHEETS ========= */
// import styles from "../styles/Home.css"
import "../styles/Home.css";


/*  ========= CONTEXT ========= */
import { Context as AuthContext } from '../context/AuthContext';

/*  ========= COOKIE ========= */
const cookies = new Cookies();

const HomePage = () => {
  const [name, setName] = useState("");
  const [connections, setConnections] = useState([]);
  const [isLoading, setIsloading] = useState(true)
  // const [enable, setEnable] = useState(false)
  // const [isEnable, setIsEnable] = useState(true);

  const navigate = useNavigate();

  const { state: authState } = useContext(AuthContext);
  const cookie = cookies.get("user");
  const jwtoken= authState.token
  const token = jwtoken || cookie

  // console.log('cookie:', cookie);
  // console.log('authState.token:', authState.token);

  // console.log(auth?.currentUser?.displayName);
  

  useEffect(() => {
    // console.log('cookie:', cookie);
    // console.log('authState.token:', authState.token);
    // console.log("ENTER HOME");
    if (!jwtoken && !cookie) {
      // Redirect to /login route
      // console.log('check jwt');
      // console.log("ENTER HOME IF TOKEN");
      navigate("/login");
    }

    mainApi
      .post("/get-destination-list", {token: token  })
      // .then((response) => response.json())
      .then((res) => {
        // Set the listSources state
        // console.log(res.data.list_sources);
        // const items = ["Item 1", "Item 2", "Item 3"];

        // REAL ONE
        setConnections(res.data.list_destinations);
        
        // console.log("res.data.list_destinations", res.data.list_destinations);

        // FOR TEST REASONS
        // const n = 30; // Number of times to replicate the object
        // const originalObject = res.data.list_destinations[0];
        // const replicatedArray = Array(n).fill(originalObject);
        // setConnections(replicatedArray);
        // END FOR TEST REASONS

        setIsloading(false)

        // console.log(res.data.list_sources[1]);
        // setListSources(items);
      })
      .catch((error) => {
        console.log(error);
        // 
      })
  }, [])

  useEffect(() => {
    // console.log(`sourceData.enable is: ${sourceData.enable}`);
    // setEnable(sourceData.enable)
  }, [connections])

  useEffect(() => {
    // console.log(`sourceData.enable is: ${sourceData.enable}`);
    // setEnable(sourceData.enable)
    // console.log("updated auth?.currentUser?.displayName");
    // console.log(auth?.currentUser?.displayName);
  }, [auth.currentUser])

  // useEffect(() => {
  // }, [connections])

  const handleAddSource = () => {
    // setName("Source");
    navigate("/source");
    // window.location.href = "/source";
  };

  const handleAddDestination = () => {
    // setName("Destination");
    navigate("/destination"); 
    // window.location.href = "/destination";
  };

  const handleAddMapping = () => {
    // setName("Mapping");
    // window.location.href = "/field-mapper";
  };

  const handleDeleteConnection = (key) => {
    // console.log("delete");
    // console.log(e.target);
    // console.log("key:", key);
    mainApi
      .delete("/delete-destination", {data: {destination_id: key, token: token }})
      .then((res) => {
        const updatedRecords = connections.filter((record) => Object.keys(record)[0] !== key);

        setConnections(updatedRecords);
      })
      .catch((error) => {
        console.log(error);
        // 
      })
  };

  const handleEnableConnection = (event,connection) => {
    event.preventDefault()
    const newEnable = event.target.checked
    const recordId = Object.keys(connection)[0]
    const enable = connection[recordId]["enable"]
    const sourceEnable = connection[recordId]["sourceEnable"]
    // console.log(`upate record: ${recordId} from ${enable} to ${!enable}`);
    // console.log(event.target.checked);
    // console.log(connection);
    // console.log(Object.keys(connection)[0]);
    // console.log(connection[Object.keys(connection)[0]]["enable"]);
    mainApi
      .put("/update-destination", {
          destination_id: recordId,
          status_enable: enable? "false": "true",
          status_enable_source: sourceEnable,
          token: token
        })
      .then((res) => {
        connection[recordId]["enable"] = !enable
        // console.log("enter then");
        setConnections([...connections])
        // setConnections([...connections, {
        //   [recordId]:{
        //     ...connection[recordId],
        //     "enable": newEnable
        //   }
        // }])
        // setIsEnable(!enable)
      })
      .catch((error) => {
        console.log(error);
        // event.target.checked = !event.target.checked
      })
  };


  return (
    <div className="app" style={{ display: 'flex' }}>
      {/* <Navbar /> */}
      {/* <div styles={{styles}}> */}
      <div className="HomeContent">
        <h1>Welcome {auth?.currentUser?.displayName}! 👋</h1>
        <div
          className="newConnectionsSection"
        >
          <button
            className="standardButton"
            onClick={handleAddSource}
          >
            Add Source
          </button>
          <button 
            className="standardButton"
            onClick={handleAddDestination}
          >
            Add Destination
          </button>
        </div>
        <h2>You connections:</h2>
          { isLoading?
              <div
                className="skeletonHome"
              >
                <Skeleton 
                  variant="rounded" 
                  width={"60vw"} 
                  height={"70vh"}
                  // animation="wave"
                  sx={{ bgcolor: 'grey.400' }}
                />
              </div>
              : <div>
                {connections.length === 0?
                  <p >No connections</p> :
                  <div
                    className="listConectionsContainer"
                  > 
                    {connections.map((connection, index) => (
                        <div  
                          className="listConectionsElement"
                          // key={index} style={{display:"inline-flex", width: "100%", justifyContent: "space-evenly"}}
                          key={index} 
                        >
                          <div style={{display:"flex"}}>
                            {/* <p> */}
                            {/* {console.log(connection)} */}
                            <Link 
                              to={`source/${connection[Object.keys(connection)]["sourceId"]}`}
                              style={{alignSelf: "center"}}
                              className="linkAtagList"
                            >
                              {connection[Object.keys(connection)]["sourceName"]}
                            </Link>
                            {/* </p> */}
                            <UilArrowRight style={{alignSelf: "center"}}/>
                            <Link 
                              to={`destination/${Object.keys(connection)}`}
                              style={{alignSelf: "center"}}
                              className="linkAtagList"
                            >
                              {connection[Object.keys(connection)]["destinationName"]}   
                            </Link>
                            {/* <p> */}
                              {/* | ID: {Object.keys(connection)} */}
                            {/* </p> */}
                          </div>
                          <div style={{display:"flex", minWidth: "130px", justifyContent: "space-evenly", alignItems: "center"}}>
                            <Switch 
                              // defaultChecked={connection[Object.keys(connection)]["enable"]} 
                              checked={connection[Object.keys(connection)]["enable"]} 
                              // checked={isEnable}
                              // key={Object.keys(connection)[0]}
                              // disabled={!connection[Object.keys(connection)]["source_enable"]} 
                              onClick={(event) => handleEnableConnection(event,connection)}
                              // onClick={handleEnableConnection}
                            />
                            <UilTrash 
                              style={{alignSelf: "center"}} 
                              onClick={() => handleDeleteConnection(Object.keys(connection)[0])}
                            />
                          </div>
                        </div>
                      ))
                    }
                  </div>
                }
              </div>
          }
          
      
        {/* <ul
          style={{
            listStyleType: "none"
          }}
        >
          {connections.map((connection, index) => (
              <li key={index}>
                <div style={{display:"inline-flex"}}>
                  <div style={{display:"flex"}}>
                    <p>
                      {connection[Object.keys(connection)]["source_name"]} -
                    </p>
                    <p>
                      {connection[Object.keys(connection)]["destination_name"]}   
                      | ID: {Object.keys(connection)}
                    </p>
                  </div>
                  <ToggleSwitch />
                  <UilTrash onClick={() => handleDeleteConnection(Object.keys(connection)[0])}/>
                </div>
              </li>
            ))
          }
        </ul> */}
        {/* <button
          className="standardButton"
          onClick={handleAddSource}
        >
          Add Source
        </button>
        <button 
          className="standardButton"
          onClick={handleAddDestination}
        >
          Add Destination
        </button> */}
        {/* <Button
          className="standardButton"
          variant="contained"
          onClick={handleAddSource}
          disableElevation
        >
          Add Source
        </Button>
        <Button
          className="standardButton"
          variant="contained"
          onClick={handleAddDestination}
          disableElevation
        >
          Add Destination
        </Button> */}
        {/* <button onClick={handleAddMapping}>Add Mapping</button> */}
      </div>
    </div>
  );
};

export default HomePage;
