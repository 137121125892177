import logo from './logo.svg';
import './App.css';
import React from 'react';
import {  BrowserRouter, Switch, Route, Link, Routes } from 'react-router-dom';

// Components
import Login from './components/Login'
import SignUp from './components/SignUp'
import Source from './components/Source'
import Destination from './components/Destination'
import Home from './components/Home'
import UpdateSource from './components/UpdateSource'
import UpdateDestination from './components/UpdateDestination'
import MainInApp from './components/MainInApp'
import List from './components/List'
import Subscription from './components/Subscription'

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          {/* <Route index element={<Login />} /> */}
          <Route path="/" exact={true} element={<MainInApp Component={Home} />} />
          <Route path="/login" exact={true} element={<Login />} />
          <Route path="/signup" exact={true} element={<SignUp />} />
          {/* <Route path="/field-mapper" element={<FieldMapper />} /> */}
          <Route path="/sources" element={<MainInApp Component={List} ListType={"source"}/>} />
          <Route path="/source" element={<MainInApp Component={Source} />} />
          <Route path="/source/:sourceId" element={<MainInApp Component={UpdateSource} />} />
          <Route path="/destinations" element={<MainInApp Component={List} ListType={"destination"}/>} />
          <Route path="/destination" element={<MainInApp Component={Destination} />} />
          <Route path="/destination/:destinationId" element={<MainInApp Component={UpdateDestination} />} />
          <Route path="/subscription" element={<MainInApp Component={Subscription}/>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// function App() {
//   return (
//       <BrowserRouter>
//         <Routes>
//           <Route path="/" exact={true}>
//             <div>
//               <h1>Home</h1>
//               <Link to="/signup">Sign Up</Link>
//               <Link to="/login">Login</Link>
//             </div>
//           </Route>
//           <Route path="/signup">
//             <Login />
//           </Route>
//           <Route path="/login">
//             <Login />
//           </Route>
//         </Routes>
//     </BrowserRouter>
//   );
// }

export default App;
