import React, { useEffect, useState } from 'react';
import Autocomplete from '@mui/joy/Autocomplete';

const SelectInputComponent = ({ placeholder, options, initValue, disabled, onChange, index }) => {
    // State variable to store the selected option or input value
    // const [autocompleteValue, setAutocompleteValue] = useState('');
    // const [value, setValue] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [defaultValue, setDefaultValue] = useState('');

    // console.log("defaultValue:", defaultValue);

    // useEffect(() => {
    //     console.log("initValue:", initValue);
    //     // console.log("options:", options);
    //     setDefaultValue(initValue)
    // }, [])

    // useEffect(() => {
    //     console.log("initValue:", initValue);
    //     // console.log("options:", options);
    //     // setDefaultValue(initValue)
    // }, [initValue])
    

    return (
        <Autocomplete
            freeSolo
            disableClearable
            placeholder={placeholder}
            options={options}
            // sx={{ width: 300 }}
            // className='Autocomplete'
            // onChange={(e) => setAutocompleteValue(e.target.value)}
            // onChange={(event, newValue) => {
            //     setValue(newValue);
            // }}
            onInputChange={(event, newInputValue) => {
                onChange(index, 'input1', newInputValue);
            }}
            defaultValue={initValue}
            // getOptionDisabled
            disabled={disabled}
        />
    );
};

export default SelectInputComponent;

