import React, { useState, useEffect } from 'react';
import Skeleton from '@mui/material/Skeleton';
import JSONViewer from './JsonViewer';
import Input from '@mui/joy/Input';

/*  ========= COMPONENTS ========= */
import SelectInputComponent from './SelectInputComponent'


/*  ========= UTILS ========= */
import addKeyValueToNestedKey from "../utils/addKeyValueToNestedKey";
import getLastMatchingElement from "../utils/getLastMatchingElement";
import findLastDuplicateIndex from "../utils/findLastDuplicateIndex";
import updateValuesChildParentKeys from "../utils/updateValuesChildParentKeys";

/*  ========= STYLESHEETS ========= */
import "../styles/FieldMapper.css";

const FieldMapper = ({fieldsSource, onValueChangeJson, onValueChangeJsonDisplay, onDuplicatedKey, editMappingJson, editMappingJsonDisplay, isUpdateDestination, onUpdateDisabled}) => {
    const [sourceValue, setSourceValue] = useState([]);
    const [destinationValue, setDestinationValue] = useState([]);
    const [nestedLevel, setNestedLevel] = useState(0);
    const [nestedEnable, setNestedEnablel] = useState(false);
    const [unnestedEnable, setUnnestedEnablel] = useState(false);
    // const [createDisnable, setCreateDisnable] = useState(false);
    const [jsonMapping, setJsonMapping] = useState({});
    const [jsonMappingString, setJsonMappingString] = useState();
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredOptions, setFilteredOptions] = useState(fieldsSource);
    // const [fieldsSource, setFieldsSource] = useState([]);
    const [rows, setRows] = useState([
        { input1: {value:'', parentKey:'', childkey:'', level: 0, valueWhenNotNested: '' }, 
        input2: {value:'', parentKey:'', childkey:'', level: 0, valueWhenNotNested: '' } }
    ]);
    const [toUpdateRows, setToUpdateRows] = useState([]);
    const [uniqueListKeysMapping, setUniqueListKeysMapping] = useState([])
    const [duplicatedKey, setDuplicatedKey] = useState({})
    const [isDuplicatedKey, setIsDuplicatedKey] = useState(false)
    const [isLoading, setIsloading] = useState(isUpdateDestination)
    // console.log(rows);


    

    useEffect(() => {
        // console.log(1);
        document.addEventListener('keydown', handleGlobalKeyDown);
        if (isUpdateDestination) {
            if(editMappingJsonDisplay != undefined && editMappingJsonDisplay != '') {
                // console.log("enter editMappingJsonDisplay");
                try {
                    const parsedObject = JSON.parse(editMappingJsonDisplay);
                    const parsedObjectInit = JSON.parse(editMappingJsonDisplay);

                    // console.log("enter first useEffect");
                    setRows(parsedObject)
                    setToUpdateRows(parsedObjectInit)
                    setIsloading(false)
                  } catch (error) {
                    console.error('Invalid JSON string:', error.message);
                  }

            }
        }
        return () => {
            // Remove the event listener when the component unmounts to avoid memory leaks
            document.removeEventListener('keydown', handleGlobalKeyDown);
          };
    }, [])

    useEffect(() => {
        // console.log(2);
        // console.log("rows[rows.length -1]: ",rows[rows.length -1]);
        if (rows[rows.length -1].input1.value != '' && rows[rows.length -1].input2.value != '') {
            // console.log("rows[rows.length -1]: ",rows[rows.length -1].input1.value);
            // console.log(`rows: ${rows}`);
            handleCreate();
        }
        // console.log("rows changed");
        // console.log(rows);
    }, [rows, nestedLevel])

    useEffect(() => {
        // console.log(3);
        // console.log("rows[rows.length -1]: ",rows[rows.length -1]);
        // console.log("uniqueListKeysMapping ",uniqueListKeysMapping);
        // console.log(findLastDuplicateIndex(uniqueListKeysMapping))
        // console.log("uniqueListKeysMapping: ", uniqueListKeysMapping);
        const indexDuplicate = findLastDuplicateIndex(uniqueListKeysMapping)
        // console.log(rows[indexDuplicate]);
        // console.log("use effect uniqueListKeysMapping");
        if (indexDuplicate != -1) {
            // console.log("indexDuplicate", indexDuplicate);
            setDuplicatedKey({
                error: `duplicated key: ${rows[indexDuplicate]['input2'].value}`, 
                keyValue: rows[indexDuplicate]['input2'].value
            })
            onDuplicatedKey(true)
            setIsDuplicatedKey(true)
        } else {
            setDuplicatedKey({})
            onDuplicatedKey(false)
            setIsDuplicatedKey(false)
        }
        // console.log(rows);
    }, [uniqueListKeysMapping])

    useEffect(() => {
        // console.log(4);
        // console.log(typeof JSON.parse(editMappingJson));
        if(editMappingJson != undefined && editMappingJson != '') {
            // console.log("editMappingJson: ", editMappingJson);
            try {
                const parsedObject = JSON.parse(editMappingJson);
                // console.log(parsedObject);
                // console.log("parsedObject: ", parsedObject);
                setJsonMapping(parsedObject)
                // let rows = [{ input1: {value:'', parentKey:'', childkey:'', level: 0 }, input2: {value:'', parentKey:'', childkey:'', level: 0 } }]
                // const testRow = iterateJSONKeys(JSON.parse(editMappingJson), 0)
                // console.log(testRow);
              } catch (error) {
                console.error('Invalid JSON string:', error.message);
              }
            // console.log(JSON.parse(editMappingJson));
            // setJsonMapping(JSON.parse(editMappingJson))
        }
    }, [editMappingJson])

    useEffect(() => {
        // console.log(5);
        // console.log(typeof JSON.parse(editMappingJson));
        // console.log("editMappingJsonDisplay: ", editMappingJsonDisplay);
        // console.log("editMappingJsonDisplay changed");
        if(editMappingJsonDisplay != undefined && editMappingJsonDisplay != '') {
            setIsloading(true)
            // console.log("enter editMappingJsonDisplay");
            try {
                const parsedObject = JSON.parse(editMappingJsonDisplay);
                // const parsedObjectInit = JSON.parse(editMappingJsonDisplay);
                // console.log("parsedObject: ", parsedObject);
                // console.log(parsedObject);
                // let rows = [{ input1: {value:'', parentKey:'', childkey:'', level: 0 }, input2: {value:'', parentKey:'', childkey:'', level: 0 } }]
                // const testRow = iterateJSONKeys(JSON.parse(editMappingJson), 0)
                // console.log(testRow);
                // console.log("enter editMappingJsonDisplay useEffect");
                setRows(parsedObject)
                // if (toUpdateRows.length == 0) {
                //     setToUpdateRows(parsedObjectInit)
                // }
                // Extract the values inside each "input2" key
                const input2Values = parsedObject.map(obj => obj.input2.value);
                // console.log(parsedObject);
                setUniqueListKeysMapping(input2Values)
                if (parsedObject[parsedObject.length - 1].level > 0) {
                    setUnnestedEnablel(true)
                }
                setNestedEnablel(true)
                setIsloading(false)
              } catch (error) {
                console.error('Invalid JSON string:', error.message);
              }
            // console.log(JSON.parse(editMappingJson));
            // setJsonMapping(JSON.parse(editMappingJson))
        }
    }, [editMappingJsonDisplay])

    const handleInputChange = (index, field, value) => {
        // event.persist();
        // const value = event.target.value
        // console.log(value);
        // console.log(index);
        // console.log("uniqueListKeysMapping", uniqueListKeysMapping);
        // console.log(`field: ${field}`);
        // console.log(`value: ${value}`);
        // console.log("toUpdateRows", toUpdateRows);
        // console.log("handleInputChange");
        // onUpdateDisabled(false)
        // console.log(rows);
        // console.log("new value:", value);
        setRows((prevRows) => {
            const rows = [...prevRows];
            let updatedRows;
            // const updatedRows = [...prevRows];
            // console.log("rows outside updateValuesChildParentKeys");
            // console.log(rows);
            if (field === 'input2') {
                updatedRows = updateValuesChildParentKeys(rows, rows[index][field].value, value)
            } else {
                updatedRows = rows
            }
            updatedRows[index][field].value = value;
            updatedRows[index][field].valueWhenNotNested = value;
            return updatedRows;
        });
        if (field === 'input2') {
            setUniqueListKeysMapping((prevKeys) => {
                const updatedKeys = [...prevKeys];
                updatedKeys[index] = value;
                return updatedKeys;
            });
        }
        // console.log(rows);
    };

    const handleAddRow = (e) => {
        // e.preventDefault();
        // console.log("press enter");
        // console.log(`nestedLevel: ${nestedLevel}`);
        e.preventDefault()
        setNestedEnablel(true)
        if (nestedLevel > 0) {
            setUnnestedEnablel(true)
            // console.log(1);
        }
        setRows((prevRows) => {
            const prevRowsLength = prevRows.length;
            // console.log(2);
            if (nestedLevel > 0) {
                return [...prevRows, 
                    { 
                    input1: {value:'', parentKey:'', childkey:'', level: nestedLevel }, 
                    input2: {value:'', parentKey:prevRows[prevRowsLength-1]['input2'].parentKey, childkey:'', level: nestedLevel } 
                }]
            }
            // console.log(3)
            return [...prevRows, 
                { input1: {value:'', parentKey:'', childkey:'', level: 0 }, input2: {value:'', parentKey:'', childkey:'', level: 0 } 
            }]
        });
    };

    const handleCreate = (e) => {
        // e.preventDefault();
        // console.log("enter handleCreate");
        // console.log("press enter create");
        const json = rows.reduce((acc, row) => {
            const { input1, input2 } = row;
            if (input2.parentKey === '' && input2.childkey === ''){
                // console.log("input2 level 1:", input2);
                acc[input2.value] = input1.value;
            } else if (input2.childkey !== '' && input2.parentKey === '') {
                // console.log("input2 level 2:", input2);
                acc[input2.value] = {}
            } else if (input2.parentKey !== '' && input2.childkey === '') {
                // console.log("input2 level 3:", input2);
                // console.log("input1 level 3:", input1);
                addKeyValueToNestedKey(acc, input2.parentKey, [input2.value], input1.value);
            } else if (input2.childkey !== '' && input2.parentKey !== ''){
                // console.log("input2 level 4:", input2);
                addKeyValueToNestedKey(acc, input2.parentKey, [input2.value], {});
            } 
            return acc;
        }, {});
        // console.log(json);
        setJsonMapping(json)
        onValueChangeJson(json)
        onValueChangeJsonDisplay(rows)
        // console.log("nestedLevel:", nestedLevel);
    };

    const handleNest = (e) => {
        // setNestedEnablel(false)
        setUnnestedEnablel(true)
        // console.log(rows);
        const newNestedLevel = nestedLevel+1
        setNestedLevel(newNestedLevel)
        setRows((prevRows) => {
            const prevRowsLength = prevRows.length;
            prevRows[prevRowsLength-1]['input2'].level = newNestedLevel
            prevRows[prevRowsLength-1]['input1'].level = newNestedLevel
            if (prevRowsLength > 1) {
                if (newNestedLevel === prevRows[prevRowsLength-2]['input1'].level) {
                    // console.log(1);
                    prevRows[prevRowsLength-1]['input2'].parentKey = prevRows[prevRowsLength-2]['input2'].parentKey
                } else if (newNestedLevel > prevRows[prevRowsLength-2]['input1'].level) {
                    // console.log(2);
                    prevRows[prevRowsLength-2]['input2'].childkey = prevRows[prevRowsLength-1]['input2'].value
                    prevRows[prevRowsLength-2]['input1'].value = {}
                    prevRows[prevRowsLength-1]['input2'].parentKey = prevRows[prevRowsLength-2]['input2'].value
                    setNestedEnablel(false)
                } else if (newNestedLevel < prevRows[prevRowsLength-2]['input1'].level) {
                    // console.log(3);
                    // Find the second-to-last object with input2 having the specified level (X)
                    const filteredObjects = prevRows.filter(obj => obj.input2.level === newNestedLevel);
                    const secondToLastInput2WithLevelX = filteredObjects[filteredObjects.length - 2];
                    prevRows[prevRowsLength-1]['input2'].parentKey = secondToLastInput2WithLevelX.input2.parentKey
                    // console.log(secondToLastInput2WithLevelX);
                    // prevRows[prevRowsLength-2]['input2'].childkey = prevRows[prevRowsLength-1]['input2'].value
                    // prevRows[prevRowsLength-2]['input1'].value = {}
                    // prevRows[prevRowsLength-1]['input2'].parentKey = prevRows[prevRowsLength-2]['input2'].value
                    // setNestedEnablel(false)
                }
                // prevRows[prevRowsLength-2]['input1'].oldValue = prevRows[prevRowsLength-2]['input1'].value
            } else if (prevRowsLength <= 1) {
                setNestedEnablel(false)
            }
            // console.log("prevRows:", prevRows);
            return prevRows
        });
        // console.log(nestedLevel);
        // handleCreate(e);
    };

    const handleUnnest = (e) => {
        setNestedEnablel(true)
        const newNestedLevel = nestedLevel-1
        // console.log(newNestedLevel);
        setNestedLevel(newNestedLevel)
        // console.log(rows);
        setRows((prevRows) => {
            const prevRowsLength = prevRows.length;
            const lastMatchingElement = getLastMatchingElement(prevRows, 'input2', newNestedLevel);
            // console.log(prevRows);
            // console.log(lastMatchingElement);
            const elementToUnnestParentKey = prevRows[prevRowsLength-1]['input2'].parentKey
            // const prevElementToUnnestParentKey = prevRows[prevRowsLength-2]['input2'].parentKey
            const prevElementToUnnestValue = prevRows[prevRowsLength-2]['input2'].parentKey
            prevRows[prevRowsLength-1]['input2'].parentKey = lastMatchingElement['input2'].parentKey
            prevRows[prevRowsLength-1]['input2'].level = newNestedLevel
            prevRows[prevRowsLength-1]['input1'].level = newNestedLevel
            if (prevElementToUnnestValue === elementToUnnestParentKey) {
                prevRows[prevRowsLength-2]['input2'].childkey = ""
                prevRows[prevRowsLength-2]['input1'].value = prevRows[prevRowsLength-2]['input1'].valueWhenNotNested
            }
            return prevRows
        })
        if (newNestedLevel === 0) {
            setUnnestedEnablel(false)
        }
        // handleCreate(e);
    };

    const handleSearch = (e) => {
        const searchTerm = e.target.value;
        setSearchTerm(searchTerm);
    
        // Filter the options based on the search term
        const filteredOptions = fieldsSource.filter((option) =>
          option.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredOptions(filteredOptions);
    };
    
    // const handleSelectOption = (option) => {
    //     setSearchTerm(option);
    //     // Perform any desired action with the selected option
    //     console.log('Selected Option:', option);
    // };

    const handleGlobalKeyDown = (e) => {
        if (e.keyCode === 13) {
          e.preventDefault(); // Prevent the default behavior (functions won't be triggered)
          // You can add additional logic here if needed
        }
    };

    // useEffect(() => {
    //     console.log(toUpdateRows);
    // }, [toUpdateRows])
    

    return (
        <div>
            {/* <div>
                <SelectInputComponent placeholder="Source Value" options={fieldsSource} />
            </div> */}
            {
                isLoading?
                <div
                    style={{
                        display:"flex",
                        justifyContent:"center"
                    }}
                >
                    <Skeleton
                        sx={{ bgcolor: 'grey.900' }}
                        variant="rectangular"
                        width={500}
                        height={300}
                    />
                </div>:   
                <div
                    
                >
                        {rows.map((row, index) => (
                            <div
                                className='keyValueMapperContainer'
                                key={index}
                            >
                                <Input
                                    type="text"
                                    placeholder="Destination Property"
                                    // className="inputMappingDest"
                                    value={row.input2.value}
                                    onChange={(e) => handleInputChange(index, 'input2', e.target.value)}
                                    // onChange={(e,newValue) => handleInputChange(index, 'input2', newValue)}
                                    disabled={fieldsSource.length < 1? true:false}
                                />
                                <SelectInputComponent
                                    className="selectMappingDest"
                                    placeholder="Source Value" 
                                    options={fieldsSource} 
                                    index={index}
                                    // onChange={(e,newValue) => handleInputChange(index, 'input1', newValue)}
                                    onChange={handleInputChange}
                                    initValue ={
                                        toUpdateRows.length > 0 && toUpdateRows.length-1 >= index? 
                                        (
                                            typeof toUpdateRows[index].input1.value === "object"? 
                                                toUpdateRows[index].input1.valueWhenNotNested : toUpdateRows[index].input1.value
                                        ): null
                                    }
                                    // initValue= {typeof toUpdateRows[index].input1.value === "object"? toUpdateRows[index].input1.valueWhenNotNested : toUpdateRows[index].input1.value === ""? null : toUpdateRows[index].input1.value}
                                    // initValue= {typeof row.input1.value === "object"? row.input1.valueWhenNotNested : row.input1.value === ""? null : row.input1.value}
                                    disabled={fieldsSource.length < 1? true:false}
                                />
                                {/* {console.log("row.input1.value: ", row.input1.value)} */}
                                {/* {console.log("toUpdateRows[index].input1.value: ", toUpdateRows[index].input1.value)} */}
                                {/* <div>
                                </div> */}
                            </div>
                        ))}
                        <div>
                            <button 
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleAddRow(e)
                                }}
                                className="standardButton"
                                disabled={isDuplicatedKey}
                            >
                                Add Row
                            </button>
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleNest()
                                }}
                                className="standardButton"
                                disabled={!nestedEnable || isDuplicatedKey}
                            >
                                Nest
                            </button>
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleUnnest()
                                }}
                                className="standardButton"
                                disabled={!unnestedEnable || isDuplicatedKey}
                            >
                                Unnest
                            </button>
                        </div>
                        <br></br>
                        <div>
                            <h3>jsonMapping:</h3> 
                            {Object.keys(duplicatedKey).length === 0?
                                null:
                                <p style={{marginBottom: "10px", color:"red"}}>{duplicatedKey.error}</p>
                            }
                            <div className="jsonMappingDiv">
                                {/* {console.log(jsonMapping)} */}
                                <JSONViewer json={jsonMapping} />
                            </div>
                        </div>
                    </div>
            }
        </div>
    );
};

export default FieldMapper;