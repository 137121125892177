import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import Cookies from "universal-cookie";
import { UilTrash, UilArrowRight } from '@iconscout/react-unicons'
import Switch from '@mui/material/Switch';
import Skeleton from '@mui/material/Skeleton';
import Input from '@mui/joy/Input';
import FormLabel from '@mui/joy/FormLabel';

/*  ========= BACKEND API ========= */
import mainApi from "../apis/mainApi";

/*  ========= CONTEXT ========= */
import { Context as AuthContext } from '../context/AuthContext';

/*  ========= STYLESHEETS ========= */
import "../styles/Source.css";

/*  ========= COOKIE ========= */
const cookies = new Cookies();

const UpdateSource = () => {
  const [sourceData, setSourceData] = useState({});
  const [sourceName, setSourceName] = useState("");
  const [sourceUrl, setSourceUrl] = useState("");
  const [sourceToken, setSourceToken] = useState("");
  const [url, setUrl] = useState("");
  const [createDisabled, setCreateDisabled] = useState(true);
  const [updateDisabled, setUpdateDisabled] = useState(true);
  const [enable, setEnable] = useState(false)
  const [isLoading, setIsloading] = useState(true)
  let { sourceId } = useParams();

  const navigate = useNavigate();

  const { state: authState } = useContext(AuthContext);
  const cookie = cookies.get("user");
  const cookieUserId = cookies.get("user_id");
  const jwtoken= authState.token
  const token = jwtoken || cookie

  useEffect(() => {
    // console.log('cookie:', cookie);
    // console.log('authState.token:', authState.token);
    if (!jwtoken && !cookie) {
      // Redirect to /login route
      console.log('check jwt');
      navigate("/login");
    }
    mainApi
      .post("/get-source-data", {
        source_id: sourceId,
        token: token
      })
      .then((response) => {
        // console.log(response.data.sourceData);
        setSourceData(response.data.sourceData);
        setSourceName(response.data.sourceData.sourceName);
        setSourceUrl(response.data.sourceData.url);
        setSourceToken(response.data.sourceData.token);
        setEnable(response.data.sourceData.enable)
        setIsloading(false)
      })
      .catch((error) => {
        console.log(error);
        // reject(error)
      })
  }, [])

  // useEffect(() => {
  //   if (sourceName != "" && sourceName.length >=3 && sourceToken.length >= 5 && sourceUrl.length >= 5) {
  //     setCreateDisabled(false)
  //   }
  // }, [sourceName, sourceUrl, sourceToken, sourceData])
  
  useEffect(() => {
    // console.log(`sourceData.enable is: ${sourceData.enable}`);
    setEnable(sourceData.enable)
  }, [sourceData])

  const handleSubmit = async () => {
    const newSourceData = {
      source_name: sourceName, 
      url: sourceUrl, 
      token: sourceToken,
      userId: cookieUserId,
      source_id: sourceId,
      token: token
    }
    
    mainApi
      .put("/update-source", newSourceData)
      .then((response) => {
        // console.log(response);
        setSourceData({...newSourceData, "enable":enable})
      })
      .then(() => {
        navigate("/")
      })
      .catch((error) => {
        console.log(error);
        // reject(error)
      })
  };

  const handleRedirect = (e) => {
    navigate("/")
  };

  const handleDeleteSource = (key) => {
    // console.log("delete");
    // console.log(e.target);
    // console.log("key:", key);
    const confirmed = window.confirm(
      'This action will disable all the connections related to this source, Do you want to continue?'
    );

    if (confirmed) {
      // Action X if user clicks "Yes"
      // console.log('Action X');
      setUpdateDisabled(false)
      mainApi
        .delete("/delete-source", {
          data: {
            source_id: sourceId,
            destinations: sourceData.destinations,
            token: token
          }
        })
        .then((res) => {
          navigate("/")
        })
        .catch((error) => {
          console.log(error);
        })
    } else {
      // Action Z if user clicks "No" or closes the prompt
      // console.log(!newEnable);
      // sourceData.enable = !newEnable
    }

  };

  const handleEnableSource = (event) => {
    event.preventDefault()
    // console.log(event.target.checked);
    // const recordId = Object.keys(connection)[0]
    // const enable = connection[recordId]["enable"]
    // console.log(`upate record: ${recordId} from ${enable} to ${!enable}`);
    // console.log(connection);
    // console.log(Object.keys(connection)[0]);
    // console.log(connection[Object.keys(connection)[0]]["enable"]);

    const newEnable = event.target.checked
    let confirmed = false
    setUpdateDisabled(false)
    if (!newEnable) {
      confirmed = window.confirm(
        'This action will disable all the connections related to this source, Do you want to continue?'
      );
    } else {
      confirmed = true
    }
    
    // console.log(`confirmed is: ${confirmed}`);
    if (confirmed) {
      // Action X if user clicks "Yes"
      // console.log('Action X');
      mainApi
        .put("/update-source", {
            source_id: sourceId,
            status_enable: newEnable? "true": "false",
            destinations: sourceData.destinations,
            token: token
          })
        .then((res) => {
          // setEnable(newEnable)
          // sourceData.enable = newEnable
          setSourceData({...sourceData, "enable":newEnable})
          // console.log(newEnable);
          // setIsEnable(!enable)
        })
        .catch((error) => {
          console.log(error);
        })
    } else {
      // Action Z if user clicks "No" or closes the prompt
      // console.log(!newEnable);
      // sourceData.enable = !newEnable
    }
  };

  return (
    <div className="SourceContent">
      <div>
        <h1>Update source: {sourceName}</h1>
        { isLoading?
            <div
              className="skeletonHome"
            >
              <Skeleton 
                variant="rounded" 
                width={"60vw"} 
                height={"70vh"}
                // animation="wave"
                sx={{ bgcolor: 'grey.400' }}
              />
            </div> :
            <div>            
              {Object.keys(sourceData).length !== 0?
                <div style={{display:"flex", minWidth: "130px", justifyContent: "space-evenly", alignItems: "center"}}>
                  <Switch 
                    checked={enable}
                    onClick={handleEnableSource}
                  />
                  <UilTrash 
                    style={{alignSelf: "center"}} 
                    onClick={handleDeleteSource}
                  />
                </div>
                : null
              }
              <form 
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <div className="divContainerSourceInput">
                  <div>
                    <FormLabel>Source name</FormLabel>
                  </div>
                  <Input 
                    type="text" 
                    value={sourceName} 
                    onChange={(e) => {
                        setSourceName(e.target.value)
                        setUpdateDisabled(false)
                      }
                    }
                    placeholder="Source Name"
                  />
                </div>
                <div className="divContainerSourceInput">
                  <div>
                    <FormLabel>Source URL</FormLabel>
                  </div>
                  <Input 
                    type="text" 
                    value={sourceUrl} 
                    onChange={(e) => {
                        setSourceUrl(e.target.value)
                        setUpdateDisabled(false)
                      }
                    }
                    placeholder="Source URL"
                  />
                </div>
                <div className="divContainerSourceInput">
                  <div>
                    <FormLabel>Source token</FormLabel>
                  </div>
                  <Input 
                    type="password" 
                    value={sourceToken} 
                    onChange={(e) => {
                        setSourceToken(e.target.value)
                        setUpdateDisabled(false)
                      }
                    }
                    placeholder="Source Token"
                  />
                </div>
                <button 
                  type="submit"
                  disabled={updateDisabled}
                  className="standardButton"
                >
                  Update
                </button>
              </form>
              {/* <button 
                onClick={handleRedirect}
                className="standardButton"
              >
                Back to Home
              </button> */}
              <h2>The URL is: /api/v1/data/{sourceId}</h2>
            </div> 
        }
      </div>
    </div>
  );
};

export default UpdateSource;
