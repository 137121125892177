/*  ========= LIBRARIES ========= */
import axios from "axios";
// import Cookies from "universal-cookie";
import { apiUrl } from "../config/config";

/*  ========= COOKIE ========= */
// const cookies = new Cookies();

// const token = cookies.get("jwt");

/*  ================== Create Intance ================== 
    Create axios intance with baseURL
*/

const instance = axios.create({
    baseURL: apiUrl
});

instance.interceptors.request.use(
    /*  ================== Create Intance ================== 
        Everytime we do a request if there is a cookie with
        a token then we assign it as a header and return it
    */
    (config) => {
        // config.headers= { 
        //     'Access-Control-Allow-Origin' : '*',
        //     'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        // }
        // config.headers= { 
        //     withCredntials: true,
        //     credentials: 'include'
        // }
        // console.log("token ", cookies);
        config.headers = {
            'Content-Type': 'application/json',
            // 'Authorization': `Bearer ${token}`,
            'Access-Control-Allow-Origin' : apiUrl,
            // 'Access-Control-Allow-Credentials': true,
            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        }
        config.withCredentials = true;
        config.credentials = 'include';
        // if (token) {
        //     config.headers.Authorization = `Bearer: ${token}`;
        // }
        return config;
    },
    // call automatically every time there is and error when we do a request
    /*  ================== Create Intance ================== 
        Call automatically every time there is and error
        when we do a request
    */
    (err) => {
        return Promise.reject(err);
    }
);

/*  ====== Return axios Intance ====== */
export default instance;