import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import Cookies from "universal-cookie";
import Skeleton from '@mui/material/Skeleton';
import Input from '@mui/joy/Input';
import FormLabel from '@mui/joy/FormLabel';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';


/*  ========= BACKEND API ========= */
import mainApi from "../apis/mainApi";

/*  ========= UTILS ========= */
import flattenJsonKeys from "../utils/flattenJsonKeys";

/*  ========= CONTEXT ========= */
import { Context as AuthContext } from '../context/AuthContext';

/*  ========= STYLESHEETS ========= */
import "../styles/Destination.css";

/*  ========= COMPONENTS ========= */
import FieldMapper from './FieldMapper'

/*  ========= COOKIE ========= */
const cookies = new Cookies();

const SourceForm = () => {
  const [destinationName, setDestinationName] = useState("");
  const [url, setUrl] = useState("");
  const [webhookToken, setWebhookToken] = useState("");
  const [mappingJson, setMappingJson] = useState("");
  const [mappingJsonStructureDisplay, setMappingJsonStructureDisplay] = useState("");
  const [editMappingJson, setEditMappingJson] = useState("");
  const [editmappingJsonStructureDisplay, seEdittMappingJsonStructureDisplay] = useState("");
  const [listSources, setListSources] = useState([]);
  const [cookieValue, setCookieValue] = useState("");
  const [fieldsSource, setFieldsSource] = useState([]);
  const [sourceName, setSourceName] = useState("");
  const [sourceId, setSourceId] = useState("");
  const [errorNoMaping, setErrorNoMaping] = useState("");
  const [updateDisabled, setUpdateDisabled] = useState(false);
  const [duplicatedKey, setDuplicatedKey] = useState(false)
  const [isLoading, setIsloading] = useState(true)
  let { destinationId } = useParams();
  // console.log(destinationId);


  const navigate = useNavigate();

  const { state: authState } = useContext(AuthContext);
  const cookie = cookies.get("user");
  const jwtoken= authState.token
  const token = jwtoken || cookie

  // if (!jwtoken && !cookie) {
  //   // Redirect to /login route
    
  //   navigate("/login");
  // }

  // console.log("updateDisabled: ", updateDisabled);

  useEffect(() => {
    // console.log('cookie:', cookie);
    // console.log('authState.token:', authState.token);
    // console.log('token', token);
    // console.log("useeffect2");
    if (!jwtoken && !cookie) {
      // Redirect to /login route
      // console.log('check jwt');
      navigate("/login");
    }

    mainApi
      .post("/get-destination-data", {
        destination_id: destinationId,
        token: token
      })
      .then((response) => {
        // console.log(response.data.destinationData);
        // setSourceData(response.data.destinationData);
        setDestinationName(response.data.destinationData.destinationName);
        setUrl(response.data.destinationData.url);
        setWebhookToken(response.data.destinationData.token);
        setSourceName(response.data.destinationData.sourceId);
        setSourceId(response.data.destinationData.sourceId);
        setMappingJson(response.data.destinationData.jsonMapping);
        setEditMappingJson(response.data.destinationData.jsonMapping);
        seEdittMappingJsonStructureDisplay(response.data.destinationData.uiStructure)
        // const selectedKey = response.data.destinationData.source_id[0]
        // const selectedObject = listSources.find(obj => obj.hasOwnProperty(selectedKey));
        // const secondElement = selectedObject[selectedKey]["data_structure"]
        // setFieldsSource(flattenJsonKeys(JSON.parse(secondElement)))
        // console.log(response.data.destinationData.json_mapping);
        // setSourceUrl(response.data.destinationData.url);
        // setSourceToken(response.data.destinationData.token);
      })
      .catch((error) => {
        console.log(error);
        // reject(error)
      })

    mainApi
      .post("/get-source-list", {token: token  })
      // .then((response) => response.json())
      .then((res) => {
        // Set the listSources state
        // console.log(res.data.list_sources);
        // const items = ["Item 1", "Item 2", "Item 3"];
        setListSources(res.data.list_sources);
        // console.log("res.data.list_sources", res.data.list_sources);
        // console.log(res.data.list_sources[1]);
        // setListSources(items);
        // console.log("stop loading");
        setIsloading(false)
      })
      .catch((error) => {
        console.log(error);
        // 
      })
  }, [])

  useEffect(() => {
    if (sourceName != '' && listSources.length > 0) {
      const selectedKey = sourceName
      // console.log(`selectedKey: ${selectedKey}`);
      // console.log(`listSources: ${listSources}`);
      const selectedObject = listSources.find(obj => obj.hasOwnProperty(selectedKey));
      // console.log("selectedObject: ", selectedObject);
      const secondElement = selectedObject[selectedKey]["dataStructure"]
      setFieldsSource(flattenJsonKeys(JSON.parse(secondElement)))
    }
  }, [sourceName, listSources])

  // useEffect(() => {
  //   if (mappingJson != "" && destinationName.length >=3 && webhookToken.length >= 5 && url.length >= 5 && !duplicatedKey) {
  //     setUpdateDisabled(false)
  //   }
  // }, [destinationName, webhookToken, mappingJson, url, duplicatedKey])

  // useEffect(() => {
  //   console.log("useeffect");

  //   mainApi
  //     .post("/get-source-list", {token: token  })
  //     // .then((response) => response.json())
  //     .then((res) => {
  //       // Set the listSources state
  //       // console.log(res.data.list_sources);
  //       // const items = ["Item 1", "Item 2", "Item 3"];
  //       setListSources(res.data.list_sources);
  //       // console.log(res.data.list_sources[1]);
  //       // setListSources(items);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       // reject(error)
  //     });
  // }, []);

  const handleSubmit = async (e) => {
    // console.log("submit");
    // console.log(sourceName);
    // console.log({
    //       destination_name: destinationName, 
    //       url: url, 
    //       tokenWebhook: webhookToken,
    //       json_mapping: JSON.stringify(mappingJson),
    //       source_id: sourceName
    //       });
          
    mainApi
      .put("/update-destination", 
      {
        destination_id: destinationId,
        destination_name: destinationName, 
        url: url, 
        tokenWebhook: webhookToken,
        json_mapping: JSON.stringify(mappingJson),
        source_id: sourceName,
        uiStructure: JSON.stringify(mappingJsonStructureDisplay),
        token: token
      })
      .then((response) => {
        // console.log(response);
        // setUrl(response.data.endpoint)
        navigate("/")
      })
      .catch((error) => {
        console.log(error);
        // reject(error)
      })
  };

  const handleOptionChange = (e, newValue) => {
    // const selectedKey = e.target.value;
    if (newValue === undefined) {
      return
    }
    const selectedKey = newValue;
    // console.log("selectedKey:", selectedKey);
    setSourceName(selectedKey);

    console.log("newValue:", newValue);
    const selectedObject = listSources.find(obj => obj.hasOwnProperty(selectedKey));
    if (selectedObject[selectedKey]["dataStructure"] === undefined) {
      // console.log("no data structure");
      // console.log("selectedObject: ", selectedObject);
      // console.log("selectedObject[selectedKey][data_structure]: ", selectedObject[selectedKey]["data_structure"]);
      setErrorNoMaping("No data of source data format, please send an even before creating the source")
      return
    } else {
      setErrorNoMaping(undefined)
    }

    if (selectedObject) {
      // console.log("in");
      const secondElement = selectedObject[selectedKey]["dataStructure"]
      // console.log('Second Element:', JSON.stringify(secondElement));
      // const test = JSON.parse(secondElement)
      // console.log(test);
      // console.log(flattenJsonKeys(JSON.parse(secondElement)));
      setFieldsSource(flattenJsonKeys(JSON.parse(secondElement)))
    }
  };

  const handleValueFromChildJson = (value) => {
    // console.log("value:", value);
    // console.log("exceute handleValueFromChildJson");
    // setUpdateDisabled(false)
    setMappingJson(value);
    // console.log(value);
  };

  const handleValueFromChildJsonDisplay = (value) => {
    // console.log("value:", value);
    // console.log("exceute handleValueFromChildJsonDisplay");
    // setUpdateDisabled(false)
    setMappingJsonStructureDisplay(value);
    // console.log(value);
  };

  const handleRedirect = () => {
    navigate("/")
  };


  //WORKS
  return (
    <div className="DestinationContent">
        <h1>Update Destination: {destinationName}</h1>
        { isLoading?
            <div
              className="skeletonHome"
            >
              <Skeleton 
                variant="rounded" 
                width={"60vw"} 
                height={"70vh"}
                sx={{ bgcolor: 'grey.400' }}
              />
            </div> :
            <div>
              <form 
                // onSubmit={handleSubmit}
                onSubmit={(e) => {
                  e.preventDefault();
              }}
              >
                <div className="divContainerDestinationInput">
                  <div>
                    <FormLabel>Destination Name</FormLabel>
                  </div>
                  <Input
                    type="text"
                    placeholder="Destination Name"
                    value={destinationName}
                    onChange={(e) => {
                        setUpdateDisabled(false)
                        console.log("set true");
                        setDestinationName(e.target.value)
                      }
                    }
                  />
                </div>
                <div className="divContainerDestinationInput">
                  <div>
                    <FormLabel>Destination URL</FormLabel>
                  </div>
                  <Input
                    type="text"
                    placeholder="Destination URL"
                    value={url}
                    onChange={(e) => {
                        setUpdateDisabled(false)
                        setUrl(e.target.value)
                      }
                    }
                  />
                </div>
                <div className="divContainerDestinationInput">
                  <div>
                    <FormLabel>Destination Token</FormLabel>
                  </div>
                  <Input
                    type="password"
                    placeholder="Destination Token"
                    value={webhookToken}
                    onChange={(e) => {
                        setUpdateDisabled(false)
                        setWebhookToken(e.target.value)
                      }
                    }
                  />
                </div>
                <div>
                  {errorNoMaping
                      ?<h3 
                          style={{marginTop: '20px', marginBottom: '10px', color: 'red'}}
                      >
                          {errorNoMaping}
                      </h3>
                      : null
                  }
                </div>
                <h2>Fields Mapping</h2>
                <div className="connectSourceDiv">
                    <h3>Source</h3>
                    <div>
                      {/* <div>
                        <label>Source Name</label>
                      </div> */}
                      {/* <label>Source Name</label> */}
                      {/* {console.log("sourceId ", sourceId)} */}
                      <Select 
                        name="source_name" 
                        onChange={(e, newValue) => handleOptionChange(e, newValue)} 
                        defaultValue={sourceId}
                        color="neutral"
                        placeholder="Select a source name"
                        size="md"
                        variant="plain"
                      >
                          <Option value="header" disabled={true}>Select a source name</Option>
                          {listSources.map((source) => (
                              // <option key={Object.keys(source)} dataKey={Object.keys(source)} value={Object.keys(source)}>{source[Object.keys(source)]["source_name"]}</option>
                              <Option 
                                key={Object.keys(source)[0]} 
                                value={Object.keys(source)[0]} 
                              >
                                {source[Object.keys(source)]["sourceName"]}
                              </Option>
                          ))}

                      </Select>
                    </div>
                </div>
                <FieldMapper 
                  fieldsSource={fieldsSource} 
                  onValueChangeJson={handleValueFromChildJson} 
                  onValueChangeJsonDisplay={handleValueFromChildJsonDisplay} 
                  onDuplicatedKey={setDuplicatedKey} 
                  onUpdateDisabled={setUpdateDisabled}
                  editMappingJson={editMappingJson}
                  editMappingJsonDisplay={editmappingJsonStructureDisplay}
                  isUpdateDestination={true}
                />
                <button 
                  type="submit" 
                  disabled={updateDisabled} 
                  onClick={handleSubmit}
                  className="standardButton"
                >
                  Update
                </button>
              </form>
              {/* <button
                className="standardButton"
                onClick={handleRedirect}
              >
                Back to Home
              </button> */}
            </div>
        }
    </div>
  );
};

export default SourceForm;
