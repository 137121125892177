import React from 'react';

const JSONViewer = ({ json }) => {
  const renderJSON = (data, level = 0) => {
    return Object.entries(data).map(([key, value]) => {
      const isObject = typeof value === 'object' && !Array.isArray(value);
      const hasNestedKeys = isObject && Object.keys(value).length > 0;
      const indent = '  '.repeat(level);
      const openingBracket = hasNestedKeys ? '{' : '';
      const closingBracket = hasNestedKeys ? '}' : '';

      return (
        <div 
          key={key} 
          style={{ textAlign: "left" }}
        >
          <span style={{ color: '#19bb19' , paddingLeft: `${15 * level}px`, fontWeight: '600' }}>{indent}{key}:</span>{' '}
          {/* <span style={{ color: 'green'}}>{indent}{key}:</span>{' '} */}
          {isObject ? openingBracket : ''}
          {isObject ? renderJSON(value, level + 1) : <span style={{ color: '#d42801', fontWeight: '600' }}>{value}</span>}
          {isObject ? <p style={{ margin: "0px", paddingLeft: `${15 * level}px`}}>{`${indent}${closingBracket}`}</p> : ''}
        </div>
      );
    });
  };

  return (
    <div style={{ marginLeft: 0, padding: "10px" }}>
      {renderJSON(json)}
    </div>
  );
};

export default JSONViewer;