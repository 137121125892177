import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import Cookies from "universal-cookie";
import Input from '@mui/joy/Input';
import FormLabel from '@mui/joy/FormLabel';

/*  ========= BACKEND API ========= */
import mainApi from "../apis/mainApi";

/*  ========= CONTEXT ========= */
import { Context as AuthContext } from '../context/AuthContext';

/*  ========= STYLESHEETS ========= */
import "../styles/Source.css";

/*  ========= COOKIE ========= */
const cookies = new Cookies();

const Source = () => {
  const [sourceName, setSourceName] = useState("");
  const [sourceUrl, setSourceUrl] = useState("");
  const [sourceToken, setSourceToken] = useState("");
  const [url, setUrl] = useState("");
  const [createDisabled, setCreateDisabled] = useState(true);

  const navigate = useNavigate();

  const { state: authState } = useContext(AuthContext);
  const cookie = cookies.get("user");
  const cookieUserId = cookies.get("user_id");
  const jwtoken= authState.token
  const token = jwtoken || cookie

  useEffect(() => {
    // console.log('cookie:', cookie);
    // console.log('authState.token:', authState.token);
    if (!jwtoken && !cookie) {
      // Redirect to /login route
      console.log('check jwt');
      navigate("/login");
    }
  }, [])

  useEffect(() => {
    if (sourceName != "" && sourceName.length >=3 && sourceToken.length >= 5 && sourceUrl.length >= 5) {
      setCreateDisabled(false)
    }
  }, [sourceName, sourceUrl, sourceToken])

  const handleSubmit = async () => {
    mainApi
      .post("/create-source", {
            source_name: sourceName, 
            url: sourceUrl, 
            token: sourceToken,
            userId: cookieUserId,
            token: token
          })
      .then((response) => {
        console.log(response);
        setUrl(response.data.endpoint)
        setSourceName("")
        setSourceToken("")
        setSourceUrl("")
      })
      .catch((error) => {
        console.log(error);
        // reject(error)
      })
    // const response = await fetch("/create", {
    //   method: "POST",
    //   body: JSON.stringify({
    //     sourceName,
    //     sourceUrl,
    //     sourceToken,
    //   }),
    // });

    // if (response.status === 200) {
    //   const url = await response.json();
    //   setUrl(url);
    // }
  };

  const handleRedirect = (e) => {
    navigate("/")
  };

  return (
    <div className="SourceContent">
      <h1>Create a new source</h1>
      <form 
        // onSubmit={handleSubmit}
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
      }}
      >
        <div className="divContainerSourceInput">
          <div>
            <FormLabel>Source name</FormLabel>
          </div>
          <Input 
            type="text" 
            value={sourceName} 
            onChange={(e) => setSourceName(e.target.value)}
            placeholder="Source Name"
          />
        </div>
        <div className="divContainerSourceInput">
          <div>
            <FormLabel>Source URL</FormLabel>
          </div>
          <Input 
            type="text" 
            value={sourceUrl} 
            onChange={(e) => setSourceUrl(e.target.value)}
            placeholder="Source URL"
          />
        </div>
        <div className="divContainerSourceInput">
          <div>
            <FormLabel>Source token</FormLabel>
          </div>
          <Input 
            type="password" 
            value={sourceToken} 
            onChange={(e) => setSourceToken(e.target.value)}
            placeholder="Source Token"
          />
        </div>
        <button 
          type="submit"
          disabled={createDisabled}
          className="standardButton"
        >
          Create
        </button>
      </form>
      <button
        className="standardButton"
        onClick={handleRedirect}
      >
        Back to Home
      </button>
      {url && <h2>The URL is: {url}</h2>}
    </div>
  );
};

export default Source;
