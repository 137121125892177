/*  ========= LIBRARIES ========= */
import createDataContext from './createDataContext'
import Cookies from "universal-cookie";

/*  ========= BACKEND API ========= */
import mainApi from "../apis/mainApi";

/*  ========= UTILS ========= */
import getSafeJsonString from "../utils/safeStringify";

/*  ========= COOKIE ========= */
const cookies = new Cookies();
const MAXAGE = 2592000000; //1 month //60 * 60 * 24 * 100;

function cleanCookie(cookieName, value, cookieAge) {
    cookies.remove(cookieName, { path: "/" });
    document.cookie = `${cookieName}=${value};max-age=${cookieAge}; path=/`;
}

/*  ========= RETRIEVE LOCAL CONTEXT DATA ========= */
let tmpStorage = JSON.parse(localStorage.getItem("authContext")) || {};
const localAuthContext = tmpStorage? tmpStorage : null;

const authReducer = (state, action) => {
    let newState = {};
    switch(action.type)  {
        case 'login':
            newState =  { ...state,
                        token: action.payload.token,
                        userInfo: {...state.userInfo, userId: action.payload.userId},
                        errorMessage: null
                    }
            break;
        case 'signup':
            newState = { ...state,
                    token: action.payload.token,
                    userInfo: {...state.userInfo, userId: action.payload.userId},
                    errorMessage: null
                    }
            break;
        // case 'firstFormCompleted':
        //     newState = { ...state,
        //                 firstTimeForm: action.payload.firstTimeForm,
        //                 userInfo: { ...state.userInfo, displayName: action.payload.displayName },
        //             }
        //     break;
        // case 'signout':
        //     newState = { ...state, token: null }
        //     break;
        // case 'error-message':
        //     newState = {...state, errorMessage: action.payload }
        //     break;
        // case "getLocalStorage":
        //     newState = { ...state,
        //                 userInfo: {...localAuthContext}
        //             };
        //     break;
        default:
            newState = state
            break;
    }
    saveContextToLocalStorage(newState.userInfo)
    return newState;
};

const loadToken = dispatch => ( token ) => {
    try {
        const initToken = cookies.get("user");
        dispatch({ type: 'signin', payload: initToken})
    } catch(err) {
        dispatch({ type: 'add_error', payload: 'Something went wrong with sign up'})
    }
}

// const signup = dispatch => ( email, password ) => {
//     try {
//         const token = "testtoken"
//         cleanCookie("token", token, MAXAGE);
//         cookies.set('token', token, {path: '/'})
//         dispatch({ type: 'signup', payload: {token, firstTimeForm:true}})
//     } catch(err) {
//         dispatch({ type: 'add_error', payload: 'Something went wrong with sign up'})
//     }
// }

// This version of how we code siginin is valid as the way we did it with sign up
// const  login = dispatch => token => {
//     try {
//         cleanCookie("token", 'testtoken', MAXAGE);
//         cookies.set('token','testtoken', {path: '/'})
//         dispatch({ type: 'login', payload: token })
//     } catch(err) {
//         dispatch({ type: 'add_error', payload: 'Something went wrong with sign up'})
//     }
// };

const  login = dispatch => ( email, password ) => {
    return new Promise((resolve,reject) => {
        mainApi
            .post("/login", { email, password })
            .then((loginResponse) => {
                cleanCookie("user", loginResponse.data.token, MAXAGE);
                cookies.set('user',loginResponse.data.token, {path: '/'})
                cookies.set('user_id',loginResponse.data.userId, {path: '/'})
                // console.log(loginResponse.data.token);
                dispatch({ type: 'login', payload: {token:loginResponse.data.token, userId:loginResponse.data.userId}})
                // console.log(loginResponse);
                resolve(loginResponse)//'logged in')
            })
            .catch((error) => {
                console.log(error);
                reject(error)
            })
    })
};

const signup = dispatch => ( email, password ) => {
    return new Promise((resolve,reject) => {
        mainApi
            .post("/sign-up", { email, password })
            .then((signUpResponse) => {
                cleanCookie("user", signUpResponse.data.token, MAXAGE);
                cookies.set('user',signUpResponse.data.token, {path: '/'})
                cookies.set('user_id',signUpResponse.data.userId, {path: '/'})
                dispatch({ type: 'signup', payload: {token:signUpResponse.data.token, firstTimeForm:true}})
                resolve(signUpResponse)//'logged in')
            })
            .catch((error) => {
                // console.log(error);
                dispatch({ type: 'error-message', payload: error.response.data})
                reject(error)
            })
    })
};

// const signin = dispatch => ( email, password ) => {
//     return new Promise((resolve,reject) => {
//         mainApi
//             .post("/sign-up", { email, password })
//             .then((signupResponse) => {
//                 mainApi
//                 .post("/login", { email, password })
//                 .then((loginResponse) => {
//                     cleanCookie("user", loginResponse.data.token, MAXAGE);
//                     cookies.set('user',loginResponse.data.token, {path: '/'})
//                     dispatch({ type: 'signup', payload: {token:loginResponse.data.token, firstTimeForm:true}})
//                     resolve('logged in')
//                 })
//             })
//             .catch((error) => {
//                 if (error.response.data.error === "email is duplicated") {
//                     mainApi
//                     .post("/login", { email, password})
//                     .then((loginResponse) => {
//                         cleanCookie("user", loginResponse.data.token, MAXAGE);
//                         cookies.set('user',loginResponse.data.token, {path: '/'})
//                         dispatch({ type: 'login',
//                         payload: 
//                             {   token: loginResponse.data.token,
//                                 userId: loginResponse.data.userId}, 
//                             }
//                         )
//                         resolve('logged in')
//                     })
//                     .catch(error => {
//                         console.log(error);
//                         dispatch({ type: 'error-message', payload: error.response.data.error})
//                         reject(error)
//                     })
//                 } else {
//                     console.log(error);
//                     reject(error)
//                 }
//                 reject('')
//             });
//     })
// }

// const firstFormCompleted = dispatch => (firstName, lastName) => {
//     dispatch({ type: 'firstFormCompleted', 
//         payload: { firstTimeForm: false, displayName: `${firstName} ${lastName}` } 
//     })
// }

const signout = (dispatch) => () => {
    // const initToken = cookies.get("user");
    // cleanCookie("user", initToken, MAXAGE);
    console.log("sign out dispatch")
    cookies.remove("user", { path: "/" });
    dispatch({ type: 'signout' })
}

const retrieveLocalStorageData = (dispatch) => () => {
    dispatch({ type: 'getLocalStorage' })
}

/*  ================== NON STATE MANAGEMENT FUNCTIONS =====================
    Non state management functions
*/

const saveContextToLocalStorage = (state) => {
    localStorage.setItem("authContext", getSafeJsonString(state));
};

export const { Provider, Context } = createDataContext(
    authReducer,
    { login, signout, signup, loadToken, retrieveLocalStorageData },
    { token: null, userId: null, userInfo: {} , errorMessage: null }
);