import React, { useState, useContext, useEffect } from "react";
import { useNavigate, Link } from 'react-router-dom';
import Cookies from "universal-cookie";
import "../styles/List.css"
import capitalizeFirstLetter from "../utils/capitalizeFirstLetter";
import Badge from '@mui/material/Badge';
import CircularComponent from './CircularComponent';
import Skeleton from '@mui/material/Skeleton';

/*  ========= BACKEND API ========= */
import mainApi from "../apis/mainApi";

/*  ========= CONTEXT ========= */
import { Context as AuthContext } from '../context/AuthContext';

/*  ========= COOKIE ========= */
const cookies = new Cookies();

const List = (props) => {
    const [listToShow, setListToShow] = useState([]);
    const [isLoading, setIsloading] = useState(true)

    const { state: authState } = useContext(AuthContext);

    const cookie = cookies.get("user");
    const jwtoken= authState.token
    const token = jwtoken || cookie


    const navigate = useNavigate();

    // console.log("props: ", props.ListType);

    useEffect(() => {
        setListToShow([]);
        setIsloading(true)
        if (!jwtoken && !cookie) {
          navigate("/login");
        }

        if (props.ListType === "source") {
            mainApi
              .post("/get-source-list", {token: token  })
              .then((res) => {
                setListToShow(res.data.list_sources);
                // console.log("res.data.list_sources", res.data.list_sources);
                setIsloading(false)
              })
              .catch((error) => {
                console.log(error);
              })
        } else if (props.ListType === "destination") {
            mainApi
            .post("/get-destination-list", {token: token  })
            .then((res) => {
                setListToShow(res.data.list_destinations);
                // console.log("res.data.list_destinations", res.data.list_destinations);
                setIsloading(false)
            })
            .catch((error) => {
              console.log(error);
            })
        }
      }, [props.ListType])


    return (
        <div className="ListContent">
            <h2>List of {capitalizeFirstLetter(props.ListType)}s:</h2>
            <div className="listBody">
            { isLoading?
              <div
                className="skeletonHome"
              >
                <Skeleton 
                  variant="rounded" 
                  width={"60vw"} 
                  height={"70vh"}
                  // animation="wave"
                  sx={{ bgcolor: 'grey.400' }}
                />
              </div>: 
              <div>
                  {listToShow.map((item, index) => (
                    <div
                      key={index}
                      className="listElement"
                    >
                        <Link 
                            key={index}
                            className="linkAtagList"
                            to={`../${props.ListType}/${Object.keys(item)}`}
                        >
                            {item[Object.keys(item)][`${props.ListType}Name`]}
                        </Link>
                        <CircularComponent isEnabled={item[Object.keys(item)]["enable"]} />
                    </div>
                    ))
                  }
              </div>
            }  
            </div>
        </div>
    );
};

export default List;
