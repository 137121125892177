import stringify from "fast-safe-stringify";

const getSafeJsonString = (obj) => {
  function tryJSONStringify(obj) {
    try {
      return JSON.stringify(obj);
    } catch (_) {}
  }

  return tryJSONStringify(obj) || stringify(obj);
};

export default getSafeJsonString;