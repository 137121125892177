const addKeyValueToNestedKey = (obj, nestedKey, key, value) => {
  // console.log(value);  
  // console.log(typeof value);  
  // Base case: If the object has the nested key, add the key-value pair
    if (obj.hasOwnProperty(nestedKey)) {
      // console.log("=========");
      // console.log("nestedKey: ", nestedKey);
      // console.log("key: ", key);
      // console.log("obj: ", obj);
      // console.log("value: ", value);
      // console.log("enter 1");
      obj[nestedKey][key] = value;
      return;
    }
  
    // Recursive case: Iterate over the object properties and search for the nested key
    for (let prop in obj) {
      if (typeof obj[prop] === 'object') {
        // console.log("enter 2");
        addKeyValueToNestedKey(obj[prop], nestedKey, key, value);
      }
    }
}

export default addKeyValueToNestedKey;