import React, { useState , useEffect, useContext, useRef} from "react";
import { useNavigate } from 'react-router-dom';
import Cookies from "universal-cookie";
import { auth, googleProvider } from "../config/firebase-config";
import {
  createUserWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from "firebase/auth";
// import GoogleButton from 'react-google-button';

/*  ========= COMPONENTS ========= */
import GoogleButton from './GoogleButton'

/*  ========= BACKEND API ========= */
import mainApi from "../apis/mainApi";

/*  ========= CONTEXT ========= */
import { Context as AuthContext } from '../context/AuthContext';

/*  ========= STYLESHEETS ========= */
import styles from "../styles/Login.css";
import "../styles/Login.css";

/*  ========= COOKIE ========= */
const cookies = new Cookies();

const App = () => {
  /*  ========= STATE ========= */
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isNotValidEmail, setIsNotValidEmail] = useState(true);
  const [isNotValidPassword, setIsNotValidPassword] = useState(true);

  /*  ========= REFERENCE ========= */
  const emailRef = useRef();
  const passwordRef = useRef();
  const getEmailRefValue = () => emailRef.current;
  const getPasswordRefValue = () => passwordRef.current;
  // const [isLoading, toggleLoading] = useBooleanToggle(false);

  /*  ========= CONTEXT ========= */
  const { state: authState, loadToken, signin, login } = useContext(AuthContext);
  const authStorage = JSON.parse(localStorage.getItem("authContext"));
  const jwtoken= authState.token

  const navigate = useNavigate();

  /*  ========= GET COOKIE ========= */
  const initToken = cookies.get("user");

  // useEffect(() => {
  //   const cookie = cookies.get("user");
  //   console.log('cookie:', cookie);
  //   console.log('authState.token:', authState.token);
  // })

  // console.log('enter login');

  useEffect(() => {
    // console.log('cookie:', initToken);
    // console.log('authState.token:', authState.token);
    if (jwtoken || initToken) {
      // Redirect to /login route
      // console.log('check jwt');
      navigate("/");
    }
  }, [])

  const signIn = () => {

    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(getEmailRefValue())) {
        // toggleLoading(true);
        // console.log(1);
        login(getEmailRefValue(), getPasswordRefValue())
        .then((res) => {
            // console.log(2)
            // console.log("res: ", res)
            if (!res) {
              // console.log(3)
                setError("We couldn't sign you in! Please try again later.");
            }
            navigate("/");
            // toggleLoading(false);
        })
        .catch((error) => {
          // console.log(5)
          console.error(error);
          // toggleLoading(false);
        });
    } else {
        // console.log(4)
        setError("Please input a valid email address!");
        // toggleLoading(false);
    }
 };

//  console.log(auth?.currentUser?.email);
 
//  const signInWithGoogle = () => {
//   signInWithPopup(auth, googleProvider)
//   .then((result) => {
//     // This gives you a Google Access Token. You can use it to access the Google API.
//     // const credential = GoogleAuthProvider.credentialFromResult(result);
//     // const token = credential.accessToken;
//     // The signed-in user info.
//     // const user = result.user;
//     // IdP data available using getAdditionalUserInfo(result)
//     // ...
//     console.log(`email: ${result.user.email}`);
//     console.log(`uid: ${result.user.uid}`);
//     console.log(`token: ${result.user.stsTokenManager.accessToken}`);
//   }).catch((error) => {
//     // Handle Errors here.
//     const errorCode = error.code;
//     const errorMessage = error.message;
//     // The email of the user's account used.
//     // const email = error.customData.email;
//     // The AuthCredential type that was used.
//     // const credential = GoogleAuthProvider.credentialFromError(error);
//     // ...
//   });
//  }

 const signInWithGoogle = async () => {
    try {
      const googleSignInResult = await signInWithPopup(auth, googleProvider);
      // const googleSignInResult = await signInWithPopup(googleProvider);
      // console.log(`googleSignInResult: ${googleSignInResult}`);
      // console.log(`googleSignInResult: ${JSON.stringify(googleSignInResult)}`);
      // console.log(`email: ${googleSignInResult.user.email}`);
      // console.log(`uid: ${googleSignInResult.user.uid}`);
      // console.log(`token: ${googleSignInResult.user.stsTokenManager.accessToken}`);
      // const credential = googleProvider.credentialFromResult(googleSignInResult);
      // console.log(`credential: ${credential}`);
      // const token = credential.accessToken;
      // console.log(`accessToken: ${token}`);
      // // The signed-in user info.
      // const user = googleSignInResult.user;
      // console.log(`result.user: ${user}`);

      mainApi
      .post("/login", 
        {
          email: googleSignInResult.user.email, 
          uid: googleSignInResult.user.uid, 
          type: 'google',
        }
      )
      .then((response) => {
        // console.log(`user success`);
        cookies.set('user',response.data.token, {path: '/'})
        cookies.set('user_id',response.data.userId, {path: '/'})
        navigate("/");
        // console.log(response);
        // setUrl(response.data.endpoint)
      })
      .catch((error) => {
        console.log(error);
        // reject(error)
      })
    } catch (err) {
      console.error(err);
    }
  };

  const handleRedirect = () => {
    navigate("/signup")
  };

  // const handleSubmit = () => {
  //   // TODO: Send email and password to backend to login user
  //   signIn()
  // };

  return (
    <div
      className="signInContent"
    >
      <div
        className="signInInsiderContainer"
      >
        <div
          className="signInInsiderContainerTop"
        >
          <h1 
            // className={{error: styles.header}}
          >
            Login
          </h1>
        </div>
        <div
          className="signInInsiderContainerMid"
        >
          <div
            style={{
              "display": "flex",
              "justifyContent": "center"
            }}
          >
            <GoogleButton
              onClickFunction={signInWithGoogle}
            />
          </div>
        </div>
        <div
          className="signInInsiderContainerBottom"
        >
          <button 
                onClick={handleRedirect}
                className="standardButton"
              >
                Back to Sign Up
            </button>
        </div>
{/*           
          <div>
            
          </div> */}
      </div>
    </div>
  );
};

export default App;
