import React from 'react';
import Navbar from './Navbar';
import "../styles/Mainapp.css"

const MainInApp = ({ Component, ...props }) => {
  return (
    <div className="main-in-app">
      <Navbar />
      {/* <div className="content" style={{maxWidth: "90vw"}}> */}
      <Component {...props} />
    {/* <div className="content">
    </div> */}
    </div>
  );
};

export default MainInApp;
